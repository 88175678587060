import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './Private';
import Layout from 'pages/Layout';
import SignIn from 'pages/SignIn';
import Clients from 'pages/Clients';
import Services from 'pages/Services';
import history from '../history';

const CustomRouter = () => {
    return (
        <Router forceRefresh={true} history={history}>
            <Switch>
                <Route exact path="/" render={(props) => <SignIn />} />
                <PrivateRoute
                    exact
                    path="/clients"
                    render={(props) => <Layout component={<Clients />} />}
                />
                <PrivateRoute
                    exact
                    path="/services"
                    render={(props) => <Layout component={<Services />} />}
                />
            </Switch>
        </Router>
    )
}

export default CustomRouter;