import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    rootFullWidth: {
        background: 'transparent',
        width: '100%',
        height: '100%'
    },
    progress: {
        color: '#00aab4'
    },
    progressFullWidth: {
        color: '#00aab4',
        position: 'fixed',
        top: '50%',
        left: '50%'
    },
    progressButton: {
        color: 'white',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    loaderCenter: {
        position: 'absolute',
        justifyContent: 'center',
        zIndex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
}));

export default useStyles;