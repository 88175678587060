import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthDataContext } from 'context/AuthContext';

const PrivateRoute = ({ component, roles = [], ...options }) => {
    const { isAuthenticated } = useAuthDataContext();

    if (!isAuthenticated) {
        return <Redirect to="/" />;
    }

    return <Route {...options} />;
};

export default PrivateRoute;