import React from 'react';
import AppBar from 'components/AppBar';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useAuthDataContext } from 'context/AuthContext';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    paragraph: {
        margin: 0,
        textAlign: 'initial'
    },
    capitalize: {
        textTransform: 'capitalize',
        textAlign: 'start'
    }
}));

export const Layout = ({ component, ...props }) => {
    const { state, setAlertProps } = useAuthDataContext();
    const classes = useStyles();

    const handleCloseAlert = () => {
        setAlertProps({
            message: '',
            show: false,
            severity: 'success'
        });
    }

    return (
        <div>
            <AppBar />
            {component}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={state.alert.show}
                autoHideDuration={3000}
                onClose={handleCloseAlert}>
                <Alert
                    variant="filled"
                    onClose={handleCloseAlert}
                    severity={state.alert.severity}
                >
                    <AlertTitle className={classes.capitalize}>{state.alert.severity}</AlertTitle>
                    <p className={classes.paragraph}>
                        {state.alert.message}
                    </p>
                </Alert>
            </Snackbar>
        </div>
    )
}

export default Layout