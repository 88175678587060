import React from 'react';
import Router from './router';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AuthDataProvider from './context/AuthContext';

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthDataProvider>
          <Router />
        </AuthDataProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;