import React, {
    createContext,
    useState,
    useEffect,
    useContext
} from 'react';
import Loader from 'components/CustomLoader';

export const AuthDataContext = createContext(null);

const AuthDataProvider = (props) => {
    const [token, setToken] = useState(window.localStorage.getItem('token'));
    const [isFirstAttempt, setFirstAttempt] = useState(true);
    const [authData, setAuthData] = useState({});
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [state, setState] = useState({
        alert: {
            show: false,
            message: '',
            severity: 'success',
        }
    });

    /* The first time the component is rendered, it tries to
     * fetch the auth data from a source, like a cookie or
     * the localStorage.
     */
    useEffect(() => {
        //let sock = socketClient(process.env.REACT_APP_API_URL);
        //setSocket(sock);
        setFirstAttempt(false);
        if (token) {
            setIsAuthenticated(true);
            const userData = {
                token
            }
            setAuthData(userData);
        } else {
            setIsAuthenticated(false);
            setAuthData({});
        }

        return () => setFirstAttempt(true);
    }, [token]);

    const onLogout = () => {
        window.localStorage.removeItem('token');
        setAuthData(null);
        setIsAuthenticated(false);
    };

    const saveUser = (user) => {
        const { access_token } = user;
        window.localStorage.setItem('token', access_token);
        setAuthData(user);
        setIsAuthenticated(true);
    }

    const setAlertProps = (props) => {
        setState({
            ...state,
            alert: props
        })
    }

    if (isFirstAttempt) {
        return (
            <div className="loader">
                <Loader />
            </div>
        );
    }

    return <AuthDataContext.Provider
        value={{
            ...authData,
            state,
            isAuthenticated,
            onLogout,
            saveUser,
            setAlertProps
        }} {...props} />;
};

export const useAuthDataContext = () => useContext(AuthDataContext);

export default AuthDataProvider;