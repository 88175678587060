import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import GroupIcon from '@material-ui/icons/Group';
import ServiceIcon from '@material-ui/icons/Storage';
import history from '../../history';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function TemporaryDrawer({ show, toggleShow }) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: show,
        bottom: false,
        right: false,
    });

    useEffect(() => {
        setState({ ...state, ["left"]: show });
    }, [show]);

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
        toggleShow(open);
    };

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem button onClick={() => history.push('/services')}>
                    <ListItemIcon><ServiceIcon /></ListItemIcon>
                    <ListItemText primary="Services" />
                </ListItem>
                <ListItem button onClick={() => history.push('/clients')}>
                    <ListItemIcon><GroupIcon /></ListItemIcon>
                    <ListItemText primary="Clients" />
                </ListItem>
            </List>
            <Divider />
        </div>
    );

    return (
        <div>
            {['left'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
