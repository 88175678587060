import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: '15px 0px 0px 0px'
        }
    },
    eyeIcon: {
        cursor: 'pointer'
    },
    emailLink: {
        color: '#00aab4'
    },
    paragraphError: {
        margin: 0
    },
    logo: {
        width: 200
    },
    buttonLogin: {
        '&:hover': {
            border: '1px solid #3fb574',
            backgroundColor: 'rgba(42, 175, 107, 0.13)'
        },
        'color': '#317745',
        'border': '1px solid rgba(63, 181, 154, 0.5)'
    },
    card: {
        maxWidth: 385,
        padding: 20,
        margin: '0 auto',
        marginTop: '10%'
    }
}));

export default useStyles;