import React from 'react';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import Backdrop from "@material-ui/core/Backdrop/Backdrop";
import useStyles from './styles';


export const ButtonLoader = ({ overwrite = {}, ...props }) => {
    const classes = useStyles();
    return <CircularProgress className={clsx(classes.progressButton, overwrite)} {...props} />
};

export const CardLoader = ({ overwrite = {}, ...props }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, classes.rootFullWidth, classes.loaderCenter, overwrite)}>
            <CircularProgress className={classes.progress} {...props} />
        </div>
    );
};

const Loader = ({ fullWidth = false, backdrop = false, ...props }) => {
    const classes = useStyles();

    if (backdrop) {
        return (
            <Backdrop className={classes.backdrop} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    return (
        <div className={(fullWidth ? classes.rootFullWidth : classes.root)}>
            <CircularProgress className={(fullWidth ? classes.progressFullWidth : classes.progress)} {...props} />
        </div>
    );
};
export default Loader;