import React, { useState, useEffect } from 'react'
import { useAuthDataContext } from 'context/AuthContext';
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ClientService } from 'services/api/clients';
import Loader from 'components/CustomLoader';
import Table from './Table';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import CustomModal from 'components/CustomModal';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    modal: {
        minWidth: 200
    }
}));

export const Clients = (props) => {
    const classes = useStyles();
    const { setAlertProps } = useAuthDataContext();
    const [pageReady, setPageReady] = useState(false);
    const [clientId, setClientId] = useState(null);
    const [serviceId, setServiceId] = useState(null);
    const [clients, setClients] = useState([]);
    const [services, setServices] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showDeleteClientModal, setShowDeleteClientModal] = useState(false);
    const [showDeleteServiceModal, setShowDeleteServiceModal] = useState(false);
    const [showAddServiceModal, setShowAddServiceModal] = useState(false);
    const [showEditServiceModal, setShowEditServiceModal] = useState(false);
    const [showModalLoader, setShowModalLoader] = useState(false);
    const [newClient, setNewClient] = useState({
        name: '',
        email: '',
        serviceId: 0,
        host: '',
        database: '',
        username: '',
        password: '',
        port: 1433
    });
    const [newService, setNewService] = useState({
        serviceId: 0,
        host: '',
        database: '',
        username: '',
        password: '',
        port: 1433
    });

    useEffect(() => {
        initialize();
    }, []);

    const initialize = () => {
        (async () => {
            const res = await getClientList();
            const res2 = await getServiceList();

            setPageReady(true);
            if (!res || !res2) {
                alert('Something went wrong, please try again');
            }
        })();
    }

    const getClientList = async () => {
        const { data, error } = await ClientService.getClients();
        if (data) {
            setClients(data);
            return true;
        }
        if (error) {
            return false;
        }
    }

    const getServiceList = async () => {
        const { data, error } = await ClientService.getServices();
        if (data) {
            setServices(data);
            return true;
        }
        if (error) {
            return false;
        }
    }

    const handleClientInputChage = event => {
        const { target: { name, value } } = event;

        setNewClient(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleServiceInputChage = event => {
        const { target: { name, value } } = event;

        setNewService(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFiltersAutocompleteChange = (event, value, name) => {
        if (!value) {
            setNewClient(prevState => ({
                ...prevState,
                [name]: 0
            }));
        } else {
            setNewClient(prevState => ({
                ...prevState,
                [name]: value.id
            }));
        }
    };

    const handleServiceAutocompleteChange = (event, value, name) => {
        if (!value) {
            setNewService(prevState => ({
                ...prevState,
                [name]: 0
            }));
        } else {
            setNewService(prevState => ({
                ...prevState,
                [name]: value.id
            }));
        }
    };

    const handleCreateClient = () => {
        const data = {
            name: newClient.name,
            email: newClient.email,
            services: [
                {
                    id: newClient.serviceId,
                    connection: {
                        host: newClient.host,
                        database: newClient.database,
                        username: newClient.username,
                        password: newClient.password,
                        port: newClient.port
                    }
                }
            ]
        };
        setShowModalLoader(true);

        ClientService.create(data)
            .then(async response => {
                if (response.data) {
                    await getClientList();
                    handleClearForm('client');
                    setShowAddModal(false);
                    setAlertProps({
                        message: 'Client created',
                        severity: 'success',
                        show: true
                    });
                }
                if (response.error) {
                    setAlertProps({
                        message: response.error,
                        severity: 'error',
                        show: true
                    });
                }
                setShowModalLoader(false);
            })

    }

    const handleDeleteClient = () => {
        setShowModalLoader(true);

        ClientService.remove(clientId)
            .then(async response => {
                if (response.data) {
                    setShowModalLoader(false);
                    await getClientList();
                    setShowDeleteClientModal(false);
                    setAlertProps({
                        message: 'Client deleted',
                        severity: 'success',
                        show: true
                    });
                }
                if (response.error) {
                    setAlertProps({
                        message: response.error,
                        severity: 'error',
                        show: true
                    });
                }
            })
    }

    const handleAddService = () => {
        const data = {
            client_id: clientId,
            service_id: newService.serviceId,
            db_host: newService.host,
            db_name: newService.database,
            db_user: newService.username,
            db_password: newService.password,
            db_port: newService.port
        };
        setShowModalLoader(true);

        ClientService.addService(data)
            .then(async response => {
                if (response.data) {
                    await getClientList();
                    handleClearForm('service');
                    setShowAddServiceModal(false);
                    setAlertProps({
                        message: 'Service added to the client',
                        severity: 'success',
                        show: true
                    });
                }
                if (response.error) {
                    setAlertProps({
                        message: response.error,
                        severity: 'error',
                        show: true
                    });
                }
                setShowModalLoader(false);
            })
    }

    const handleEditConnection = () => {
        const data = {
            service_id: newService.serviceId,
            db_host: newService.host,
            db_name: newService.database,
            db_user: newService.username,
            db_password: newService.password,
            db_port: newService.port
        };
        setShowModalLoader(true);

        ClientService.updateService(serviceId, clientId, data)
            .then(async response => {
                if (response.data) {
                    handleCloseEditConnectionModal();
                    await getClientList();
                    handleClearForm('service');
                    setAlertProps({
                        message: 'The changes were applied',
                        severity: 'success',
                        show: true
                    });
                }
                if (response.error) {
                    setAlertProps({
                        message: response.error,
                        severity: 'error',
                        show: true
                    })
                }
                setShowModalLoader(false);
            })
    }

    const handleValidate = () => {
        return (
            newClient.name !== '' &&
            newClient.email !== '' &&
            newClient.serviceId !== 0 &&
            newClient.host !== '' &&
            newClient.database !== '' &&
            newClient.username !== '' &&
            newClient.password !== '' &&
            newClient.port !== ''
        )
    }

    const handleValidateService = () => {
        return (
            newService.serviceId !== 0 &&
            newService.host !== '' &&
            newService.database !== '' &&
            newService.username !== '' &&
            newService.password !== '' &&
            newService.port !== ''
        )
    }

    const handleClearForm = (type) => {
        const data = type === 'client'
            ? {
                name: '',
                email: '',
                serviceId: 0,
                host: '',
                database: '',
                username: '',
                password: '',
                port: 1433
            }
            : {
                serviceId: 0,
                host: '',
                database: '',
                username: '',
                password: '',
                port: 1433
            }
        setNewClient(data);
    }

    const handleToggleAddServiceModal = (show, client) => {
        setShowAddServiceModal(show);
        setClientId(client);
    }

    const handleOpenDeleteClientModal = (show, client) => {
        setShowDeleteClientModal(true);
        setClientId(client);
    }

    const handleOpenDeleteServiceModal = (service, client) => {
        setShowDeleteServiceModal(true);
        setServiceId(service);
        setClientId(client);
    }

    const handleCloseDeleteClientModal = () => {
        setShowDeleteClientModal(false);
        setClientId(null);
    }

    const handleCloseDeleteServiceModal = () => {
        setShowDeleteServiceModal(false);
        setServiceId(null);
    }

    const handleCloseAddServiceModal = () => {
        setShowAddServiceModal(false);
        setClientId(null);
    }

    const handleDeleteService = () => {
        ClientService.removeService(serviceId, clientId)
            .then(async response => {
                if (response.data) {
                    await getClientList();
                    setShowDeleteServiceModal(false);
                    setAlertProps({
                        message: 'Service removed from the client',
                        severity: 'success',
                        show: true
                    });
                }
                if (response.error) {
                    setAlertProps({
                        message: response.error,
                        severity: 'error',
                        show: true
                    });
                }
                setShowModalLoader(false);
            })
    }

    const handleOpenEditConnectionModal = (id, client) => {
        setClientId(client);
        setServiceId(id);
        const selectedClient = clients.filter(item => item.id === client);
        const conn = selectedClient[0].api_key.connections.filter(connection => connection.id === id);
        setNewService({
            serviceId: conn[0].service_id,
            host: conn[0].db_host,
            database: conn[0].db_name,
            username: conn[0].db_user,
            password: conn[0].db_password,
            port: conn[0].db_port
        });
        setShowEditServiceModal(true);

    }

    const handleCloseEditConnectionModal = () => {
        setClientId(null);
        setServiceId(null);
        setNewService({
            serviceId: 0,
            host: '',
            database: '',
            username: '',
            password: '',
            port: ''
        });
        setShowEditServiceModal(false);
    }

    if (!pageReady) return <Loader fullWidth />

    return (
        <div>
            <Typography component="p">
                Clients
                <IconButton aria-label="Add"
                    onClick={() => setShowAddModal(true)}
                    className={classes.margin}>
                    <PersonAddIcon
                        fontSize="small"
                    />
                </IconButton>
            </Typography>
            <Container>
                <Table
                    clients={clients}
                    services={services}
                    openServiceModal={handleToggleAddServiceModal}
                    openDeleteClientModal={handleOpenDeleteClientModal}
                    openDeleteServiceModal={handleOpenDeleteServiceModal}
                    openEditConnectionModal={handleOpenEditConnectionModal}
                />
            </Container>
            <CustomModal
                fullWidth
                showLoader={showModalLoader}
                overwrite={classes.modal}
                title="New Client"
                open={showAddModal}
                close={() => setShowAddModal(false)}
                confirm={handleCreateClient}
                disabled={!handleValidate()}
                buttonText="Create"
            >
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleClientInputChage}
                        value={newClient.name}
                        id="name"
                        name="name"
                        label="Client Name"
                        type="text"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleClientInputChage}
                        value={newClient.email}
                        id="email"
                        name="email"
                        label="Email"
                        type="email"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box>
                    <Autocomplete
                        className={clsx(classes.autocomplete, classes.margin)}
                        disabled={false}
                        name="service"
                        options={services}
                        onChange={(event, value) => handleFiltersAutocompleteChange(event, value, 'serviceId')}
                        getOptionLabel={(option) => option.name.toUpperCase()}
                        value={services.filter(service => service.id === newClient.serviceId)[0] || null}
                        renderInput={(params) =>
                            <TextField
                                required
                                {...params}
                                label="Service"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        }
                    />
                </Box>
                <Typography style={{ textAlign: 'center' }}>Database connection settings</Typography>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleClientInputChage}
                        value={newClient.host}
                        id="host"
                        name="host"
                        label="Host"
                        type="text"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleClientInputChage}
                        value={newClient.database}
                        id="database"
                        name="database"
                        label="Database name"
                        type="text"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleClientInputChage}
                        value={newClient.username}
                        id="username"
                        name="username"
                        label="Username"
                        type="text"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleClientInputChage}
                        value={newClient.password}
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleClientInputChage}
                        value={newClient.port}
                        id="port"
                        name="port"
                        label="Port"
                        type="number"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
            </CustomModal>
            <CustomModal
                fullWidth
                showLoader={showModalLoader}
                overwrite={classes.modal}
                title="Add Service"
                open={showAddServiceModal}
                close={handleCloseAddServiceModal}
                confirm={handleAddService}
                disabled={!handleValidateService()}
                buttonText="Add"
            >
                <Box>
                    <Autocomplete
                        className={clsx(classes.autocomplete, classes.margin)}
                        disabled={false}
                        name="service"
                        options={services}
                        onChange={(event, value) => handleServiceAutocompleteChange(event, value, 'serviceId')}
                        getOptionLabel={(option) => option.name.toUpperCase()}
                        value={services.filter(service => service.id === newService.serviceId)[0] || null}
                        renderInput={(params) =>
                            <TextField
                                required
                                {...params}
                                label="Service"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        }
                    />
                </Box>
                <Typography style={{ textAlign: 'center' }}>Database connection settings</Typography>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleServiceInputChage}
                        value={newService.host}
                        id="host"
                        name="host"
                        label="Host"
                        type="text"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleServiceInputChage}
                        value={newService.database}
                        id="database"
                        name="database"
                        label="Database name"
                        type="text"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleServiceInputChage}
                        value={newService.username}
                        id="username"
                        name="username"
                        label="Username"
                        type="text"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleServiceInputChage}
                        value={newService.password}
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleServiceInputChage}
                        value={newService.port}
                        id="port"
                        name="port"
                        label="Port"
                        type="number"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
            </CustomModal>
            <CustomModal
                title="Delete Client"
                buttonText="confirm"
                open={showDeleteClientModal}
                close={handleCloseDeleteClientModal}
                confirm={handleDeleteClient}
                showLoader={showModalLoader}
                fullWidth
            >
                Are you sure you want to delete this client?
                <br />
                <br />
                <br />
                This is a permanent action and the client will no longer be able to connect to the services.
            </CustomModal>
            <CustomModal
                title="Delete Service"
                buttonText="confirm"
                open={showDeleteServiceModal}
                close={handleCloseDeleteServiceModal}
                confirm={() => handleDeleteService()}
                showLoader={showModalLoader}
                fullWidth
            >
                Are you sure you want to delete this service?
                <br />
                <br />
                <br />
                This is a permanent action and the client will no longer be able to connect to this service.
            </CustomModal>
            <CustomModal
                fullWidth
                showLoader={showModalLoader}
                overwrite={classes.modal}
                title="Edit Service"
                open={showEditServiceModal}
                close={handleCloseEditConnectionModal}
                confirm={handleEditConnection}
                disabled={!handleValidateService()}
                buttonText="Edit"
            >
                <Box>
                    <Autocomplete
                        className={clsx(classes.autocomplete, classes.margin)}
                        disabled={false}
                        name="service"
                        options={services}
                        onChange={(event, value) => handleServiceAutocompleteChange(event, value, 'serviceId')}
                        getOptionLabel={(option) => option.name.toUpperCase()}
                        value={services.filter(service => service.id === newService.serviceId)[0] || null}
                        renderInput={(params) =>
                            <TextField
                                required
                                {...params}
                                label="Service"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        }
                    />
                </Box>
                <Typography style={{ textAlign: 'center' }}>Database connection settings</Typography>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleServiceInputChage}
                        value={newService.host}
                        id="host"
                        name="host"
                        label="Host"
                        type="text"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleServiceInputChage}
                        value={newService.database}
                        id="database"
                        name="database"
                        label="Database name"
                        type="text"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleServiceInputChage}
                        value={newService.username}
                        id="username"
                        name="username"
                        label="Username"
                        type="text"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleServiceInputChage}
                        value={newService.password}
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleServiceInputChage}
                        value={newService.port}
                        id="port"
                        name="port"
                        label="Port"
                        type="number"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
            </CustomModal>
        </div>
    )
}

export default Clients