import { get, post, put, destroy } from 'config/api'

const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
}

export const ClientService = {
    getClients: () => get(`/clients`),
    getServices: () => get('/services'),
    create: (data) => post('/clients', data),
    update: (id, data) => put(`/clients/${id}`, data),
    remove: (id) => destroy(`/clients/${id}`),
    addService: (data) => post('/clients/connection', data),
    updateService: (id, client, data) => put(`/clients/connection/${id}/${client}`, data),
    removeService: (id, client) => destroy(`/clients/connection/${id}/${client}`)
}