import React, { useReducer, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { TextField, Link, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Loader from 'components/CustomLoader';
import Button from '@material-ui/core/Button';
import { useAuthDataContext } from '../../context/AuthContext';
import signInReducer from './reducer';
import actions from './actions';
import { AuthService } from '../../services/api/auth'
import useStyles from './styles';
import './styles.css';

import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

const SignIn = () => {
    const [snackError, setSnackError] = useState({
        show: false,
        message: ''
    });
    const { saveUser, isAuthenticated } = useAuthDataContext();
    const classes = useStyles();
    const initialState = {
        credentials: {
            password: '',
            username: ''
        },
        error: {
            username: {
                message: ''
            },
            password: {
                message: ''
            }
        },
        passwordInputType: 'password',
        showLoader: false,
        invalidEmail: false,
        emailNotFound: false,
        wrongPassword: false,
        apiError: false,
        hasErrorForm: false,
        openToast: false
    };
    const [state, dispatch] = useReducer(signInReducer, initialState);
    const {
        showLoader,
        passwordInputType,
        invalidEmail,
        emailNotFound,
        wrongPassword,
        apiError,
        error,
        credentials: { username, password }
    } = state;
    const handleChange = (e) => {
        const { id, value } = e.target;

        if (id === 'password' && value === '') {
            dispatch({ type: actions.wrongPassword, value: false });
        }
        if (id === 'username' && value === '') {
            dispatch({ type: actions.emailNotFound, value: false });
            dispatch({ type: actions.showInvalidEmail, value: false });
        }

        dispatch({
            type: actions.storeCredentials,
            value: {
                key: id,
                value: value
            }
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch({ type: actions.clearErrors });
        dispatch({ type: actions.showLoader, value: true });
        const params = {
            grant_type: 'password',
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            password: state.credentials.password,
            username: state.credentials.username,
            scope: ""
        }
        AuthService.login(params)
            .then((response) => {
                if (response.error) {
                    setSnackError({
                        show: true,
                        message: response.error_description
                    });
                    dispatch({ type: actions.showLoader, value: false });
                }
                if (response.access_token) {
                    saveUser(response)
                }
            })
            .catch(error => {
                console.log(error)
                setSnackError({
                    show: true,
                    message: 'Something went wrong, please try again...'
                });
                dispatch({ type: actions.showLoader, value: false });
            });
    };
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackError({
            show: false,
            message: ''
        });
    };

    // Render redirect component in case there is a user session, if not then render the original component.
    // This is only in the components that you want to redirect if there is a session
    return isAuthenticated ?
        <Redirect to="/clients" /> :
        (
            <div className="sign-in-container">
                <Container>
                    <Card className={classes.card} variant="outlined">
                        <CardHeader title="BST Admin" />
                        <CardContent>
                            <div className="sign-in-form">
                                <form
                                    className={classes.root}
                                    noValidate
                                    onSubmit={handleSubmit}
                                    autoComplete="off">
                                    <TextField
                                        fullWidth
                                        type="email"
                                        error={emailNotFound || invalidEmail}
                                        id="username"
                                        onChange={handleChange}
                                        label="Username"
                                        value={state.credentials.email}
                                        helperText={error.username.message}
                                        variant="outlined" />
                                    <TextField
                                        fullWidth
                                        error={wrongPassword}
                                        id="password"
                                        type={passwordInputType}
                                        onChange={handleChange}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    onClick={() => dispatch({ type: 'CHANGE_INPUT_TYPE', value: (passwordInputType === 'password' ? 'text' : 'password') })}
                                                    className={classes.eyeIcon}>
                                                    {passwordInputType === 'password' ? <Visibility /> : <VisibilityOff />}
                                                </InputAdornment>
                                            )
                                        }}
                                        helperText={error.password.message}
                                        label="Password"
                                        value={state.credentials.password}
                                        variant="outlined" />
                                    <Button
                                        size="large"
                                        type="submit"
                                        disabled={username === '' || password === '' || state.showLoader || state.hasErrorForm}
                                        fullWidth
                                        color="primary"
                                        variant="outlined">
                                        SIGN IN
							</Button>
                                    {showLoader && <Loader fullWidth />}
                                    <Snackbar open={apiError} autoHideDuration={10000} onClose={handleClose}>
                                        <Alert onClose={handleClose} severity="warning">
                                            <p className={classes.paragraphError}>
                                                Lo sentimos, estamos teniendo problemas para conectarte a nuestro sitio en este momento.
									Por favor contáctenos en <Link className={classes.emailLink} href="mailto:info@siatss.com" target="_top">info@siatss.com </Link> para asegurarnos de avisarle cuando las cosas vuelvan a funcionar normalmente.
									</p>
                                        </Alert>
                                    </Snackbar>
                                    <Snackbar open={snackError.show} autoHideDuration={3000} onClose={handleClose}>
                                        <Alert onClose={handleClose} severity="warning">
                                            <p className={classes.paragraphError}>
                                                {snackError.message}
                                            </p>
                                        </Alert>
                                    </Snackbar>
                                </form>
                            </div>
                        </CardContent>
                    </Card>
                </Container>
            </div>
        );
};

export default SignIn;