import React, { useState, useEffect } from 'react';
import { useAuthDataContext } from 'context/AuthContext';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { makeStyles } from '@material-ui/core/styles';
import Table from './Table';
import { ServicesService } from 'services/api/services';
import Loader from 'components/CustomLoader';
import CustomModal from 'components/CustomModal';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';



const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

export const Services = (props) => {
    const classes = useStyles();
    const { setAlertProps } = useAuthDataContext();
    const [service, setService] = useState(null);
    const [showModalLoader, setShowModalLoader] = useState(false);
    const [showDeleteServiceModal, setShowDeleteServiceModal] = useState(false);
    const [showAddServiceModal, setShowAddServiceModal] = useState(false);
    const [newService, setNewService] = useState({
        serviceId: 0,
        name: '',
        host: '',
        prefix: '',
        include_prefix: true
    });
    const [services, setServices] = useState([]);
    const [pageReady, setPageReady] = useState(false);

    useEffect(() => {
        initialize();
    }, [])

    const initialize = async () => {
        await handleGetServices();
        setPageReady(true);
    }

    const handleGetServices = async () => {
        const { data } = await ServicesService.getServices();

        if (data) {
            setServices(data);
            return true;
        }

        return false;
    }

    const handleInputChange = event => {
        setNewService({
            ...newService,
            [event.target.name]: event.target.value
        });
    };

    const handleCheckboxChange = event => {
        setNewService({
            ...newService,
            [event.target.name]: event.target.checked
        });
    }

    const handleCloseAddServiceModal = () => {
        setShowAddServiceModal(false);
        handleClearFormService();
    };
    const handleCreateService = () => {
        const data = newService;
        setShowModalLoader(true);

        ServicesService.create(data)
            .then(async response => {
                if (response.data) {
                    await handleGetServices();
                    setShowModalLoader(false);
                    handleCloseAddServiceModal();
                }
            })
    };
    const handleValidateService = () => {
        return (
            newService.name !== '' &&
            newService.host !== '' &&
            newService.prefix !== ''
        )
    };
    const handleClearFormService = () => {
        setNewService({
            serviceId: 0,
            name: '',
            host: '',
            prefix: '',
            include_prefix: true
        });
    };
    const handleOpenDeleteServiceModal = (service) => {
        setShowDeleteServiceModal(true);
        setService(service);
    };
    const handleCloseDeleteServiceModal = () => {
        setShowDeleteServiceModal(false);
        setService(null);
    };
    const handleDeleteService = () => {
        setShowModalLoader(true);

        ServicesService.remove(service)
            .then(async response => {
                if (response.data) {
                    setShowModalLoader(false);
                    await handleGetServices();
                    handleCloseDeleteServiceModal();
                }
            })
    };

    if (!pageReady) return <Loader fullWidth />

    return (
        <div>
            <Typography component="p">
                Services
                <IconButton aria-label="Add"
                    onClick={() => setShowAddServiceModal(true)}
                    className={classes.margin}>
                    <PersonAddIcon
                        fontSize="small"
                    />
                </IconButton>
            </Typography>
            <Container>
                <Table
                    rows={services}
                    openDeleteModal={handleOpenDeleteServiceModal}
                />
            </Container>
            <CustomModal
                fullWidth
                showLoader={showModalLoader}
                title="Create Service"
                open={showAddServiceModal}
                close={handleCloseAddServiceModal}
                confirm={handleCreateService}
                disabled={!handleValidateService()}
                buttonText="Create"
            >
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleInputChange}
                        value={newService.name}
                        id="name"
                        name="name"
                        label="Service Name"
                        type="text"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleInputChange}
                        value={newService.host}
                        id="host"
                        name="host"
                        label="Host"
                        type="text"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box style={{ display: 'block' }}>
                    <TextField
                        className={classes.margin}
                        style={{ width: '100%' }}
                        onChange={handleInputChange}
                        value={newService.prefix}
                        id="prefix"
                        name="prefix"
                        label="prefix"
                        type="text"
                        required
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={newService.include_prefix}
                                onChange={handleCheckboxChange}
                                name="include_prefix"
                                color="primary"
                            />
                        }
                        label="Include prefix"
                    />
                </Box>
            </CustomModal>
            <CustomModal
                title="Delete Service"
                buttonText="confirm"
                open={showDeleteServiceModal}
                close={handleCloseDeleteServiceModal}
                confirm={handleDeleteService}
                showLoader={showModalLoader}
                fullWidth
            >
                Are you sure you want to delete this service?
                <br />
                <br />
                <br />
                This is a permanent action and clients who have access to this service will lose the ability to connect
            </CustomModal>
        </div>
    )
}

export default Services