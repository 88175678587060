import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Chip from '@material-ui/core/Chip';
import { dateFormat } from 'utils/DateFormatter'

const useRowStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset'
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
    subHeader: {
        paddingLeft: 5,
        backgroundColor: 'cadetblue'
    }
}));

function Row(props) {
    const {
        row,
        onOpenServiceModal,
        onOpenDeleteClientModal,
        onOpenDeleteServiceModal,
        onOpenEditConnectionModal
    } = props;
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = React.useState(true);
    const classes = useRowStyles();

    const showKey = (key, visible = false) => {
        if (visible) {
            return key;
        }
        return '****************************************************************';
    }

    const handleChangeVisibility = () => {
        setShow((current) => !current);
    }

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>{showKey(row.api_key.key, !show)} {show ? <Visibility onClick={handleChangeVisibility} /> : <VisibilityOff onClick={handleChangeVisibility} />}</TableCell>
                <TableCell>{dateFormat(row.created_at)}</TableCell>
                <TableCell>
                    <IconButton aria-label="Delete"
                        onClick={() => onOpenDeleteClientModal(true, row.id)}
                        className={classes.margin}>
                        <DeleteIcon
                            fontSize="small"
                        />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div" className={classes.subHeader}>
                                Services
                                <IconButton aria-label="Add"
                                    onClick={() => onOpenServiceModal(true, row.id)}
                                    className={classes.margin}>
                                    <AddCircleIcon
                                        fontSize="small"
                                    />
                                </IconButton>
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Prefix</TableCell>
                                        <TableCell>Host</TableCell>
                                        <TableCell>Database</TableCell>
                                        <TableCell>Username</TableCell>
                                        <TableCell>Password</TableCell>
                                        <TableCell> </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.api_key.connections.map((ServiceRow) => {
                                        return (
                                            <TableRow key={ServiceRow.id}>
                                                <TableCell component="th" scope="row">
                                                    {ServiceRow.id}
                                                </TableCell>
                                                <TableCell>
                                                    <Chip color="primary" variant="outlined" size="small" label={ServiceRow?.service?.name} />
                                                </TableCell>
                                                <TableCell>
                                                    <Chip color="primary" variant="outlined" size="small" label={ServiceRow?.service?.prefix} />
                                                </TableCell>
                                                <TableCell>
                                                    <Chip color="primary" variant="outlined" size="small" label={ServiceRow.db_host} />
                                                </TableCell>
                                                <TableCell>
                                                    <Chip color="primary" variant="outlined" size="small" label={ServiceRow.db_name} />
                                                </TableCell>
                                                <TableCell>
                                                    <Chip color="primary" variant="outlined" size="small" label={ServiceRow.db_user} />
                                                </TableCell>
                                                <TableCell>
                                                    <Chip color="primary" variant="outlined" size="small" label={ServiceRow.db_password} />
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        title="Delete"
                                                        aria-label="delete"
                                                        onClick={() => onOpenDeleteServiceModal(ServiceRow.id, row.id)}
                                                    >
                                                        <DeleteIcon fontSize="default" />
                                                    </IconButton>
                                                    <IconButton
                                                        title="Update"
                                                        aria-label="update"
                                                        onClick={() => onOpenEditConnectionModal(ServiceRow.id, row.id)}
                                                    >
                                                        <EditIcon fontSize="default" />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                    {row.api_key.connections.length <= 0 &&
                                        <TableRow>
                                            <TableCell>
                                                There are no services attached to this user yet
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function CollapsibleTable({
    clients,
    services,
    openServiceModal,
    openDeleteClientModal,
    openDeleteServiceModal,
    openEditConnectionModal
}) {
    const [data, setData] = useState(clients);
    const [items, setItems] = useState(services);

    useEffect(() => {
        setData(clients);
    }, [clients])

    useEffect(() => {
        setItems(services);
    }, [services])

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow style={{ backgroundColor: '#a9a9a96e' }}>
                        <TableCell />
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>ApiKey</TableCell>
                        <TableCell>Created at</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <Row
                            key={row.id}
                            row={row}
                            services={items}
                            onOpenServiceModal={openServiceModal}
                            onOpenDeleteClientModal={openDeleteClientModal}
                            onOpenDeleteServiceModal={openDeleteServiceModal}
                            onOpenEditConnectionModal={openEditConnectionModal}
                        />
                    ))}
                    {data.length <= 0 &&
                        <TableRow>
                            <TableCell>
                                There are no results to display yet
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    );
}
