import actions from './actions';

const reducer = (state, action) => {
    switch (action.type) {
        case actions.storeCredentials: {
            return {
                ...state,
                credentials: {
                    ...state.credentials,
                    [action.value.key]: action.value.value
                }
            };
        }
        case actions.clearCredentials: {
            return {
                ...state,
                credentials: {
                    ...state.credentials,
                    email: '',
                    password: ''
                }
            };
        }
        case actions.emailNotFound: {
            return {
                ...state,
                error: {
                    ...state.error,
                    email: {
                        ...state.error.email,
                        message: action.value
                    }
                },
                emailNotFound: true
            };
        }
        case actions.wrongPassword: {
            return {
                ...state,
                error: {
                    ...state.error,
                    password: {
                        ...state.error.password,
                        message: action.value
                    }
                },
                wrongPassword: true
            };
        }
        case actions.showInvalidEmail: {
            return {
                ...state,
                invalidEmail: action.value
            };
        }
        case actions.showApiError: {
            return {
                ...state,
                apiError: action.value
            };
        }
        case actions.clearErrors: {
            return {
                ...state,
                emailNotFound: false,
                wrongPassword: false,
                invalidEmail: false,
                error: {
                    ...state.error,
                    email: {
                        ...state.error.email,
                        message: ''
                    },
                    password: {
                        ...state.error.password,
                        message: ''
                    }
                },
            };
        }
        case actions.disableFormSubmit: {
            return {
                ...state,
                hasErrorForm: action.value
            };
        }
        case actions.showLoader: {
            return {
                ...state,
                showLoader: action.value
            }
        }
        case actions.changeInputType: {
            return {
                ...state,
                passwordInputType: action.value
            }
        }
        default:
            return state;
    }
};

export default reducer;